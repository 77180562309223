<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    item-key="id"
    sort-by="date"
    sort-desc
    class="elevation-1"
    @current-items="currentItems"
    :loading="loading"
    loading-text="Loading... Please wait"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Invoices List</v-toolbar-title>
        
        <v-spacer></v-spacer>
        
        
        <v-text-field
          v-if="search_tool"
          v-model="search"
          ref="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>

        <!-- BOM Filtros -->
        <v-dialog v-if="search_tool" v-model="dialogFilters" max-width="600px">
          <template v-slot:activator="{ on }">
            <v-btn
              class="ml-4"
              color="blue-grey"
              fab
              small
              dark
              v-on="on"
              @click="dialogFilters = true"
            >
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Advanced Search</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-menu
                      ref="rangeCalendar"
                      v-model="rangeCalendar"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-x
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="dateRangeText"
                          label="Dates"
                          hint="Enter a date range range. Separate with '~'"
                          persistent-hint
                          prepend-icon="event"
                          v-on="on"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="rangeDates" no-title range>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="rangeDates = []">Clear</v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.rangeCalendar.save(rangeDates);"
                        >OK</v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6" sm="6">
                    <v-text-field
                    v-model="filterWorkOrder"
                      label="Work Order"
                      single-line
                      hide-details
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="6">
                    <v-autocomplete
                      :items="[
                        'All',
                        'Yes',
                        'No'
                      ]"
                      label="Paid"
                      v-model="filterPaid"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="clearFilterItems()">Clear Filters</v-btn>
              <v-btn color="blue darken-1" text @click="dialogFilters = false;">Close</v-btn>
              <v-btn color="blue darken-1" text @click="filterItems()">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- EOM Filtros -->

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="750px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" 
              v-on="on" 
              @click="formNewItem()"
            >New Invoice</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row v-if="formNewtItem" class="form-first-field">
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      prepend-icon="folder"
                      placeholder="Select Work Order"
                      :items="workOrdersList"
                      item-text="id"
                      item-value="id"
                      label="Work Order"
                      v-model="current_wo_id"
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6" sm="6" md="6">
                      <v-text-field v-model="editedItem.number" type="text" prefix="" label="Number"></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="6" md="6">
                      <v-text-field
                        label="To"
                        name="company_label"
                        type="text"
                        v-model="editedItem.company_label"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="editedItem.amount" type="number" prefix="$" label="Amount"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      v-model="popDatepick1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="editedItem.date"
                          label="Date"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="editedItem.date" @input="popDatepick1 = false"></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12" md="12"></v-col>
                    <v-textarea
                                autocomplete="Notes"
                                label="Notes"
                                rows="5"
                                v-model="editedItem.notes"
                              >
                    </v-textarea>
                    </v-col>          
                  </v-row>
                  <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-switch v-model="editedItem.final_invoice" color="info" hide-details>
                      <template v-slot:label>
                        Final Invoice:
                        <v-chip color="green" dark v-if="editedItem.final_invoice == 1" class="ml-2">Yes</v-chip>
                        <v-chip color="red" dark v-else class="ml-2">No</v-chip>
                      </template>
                    </v-switch>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-switch v-model="editedItem.paid" color="info" hide-details>
                      <template v-slot:label>
                        Paid:
                        <v-chip color="green" dark v-if="editedItem.paid == 1" class="ml-2">Yes</v-chip>
                        <v-chip color="red" dark v-else class="ml-2">No</v-chip>
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save(items)">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialog2" max-width="750px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle2 }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="7" md="7">
                    <v-switch v-model="editedItem.final_invoice" color="info" hide-details>
                      <template v-slot:label>
                        Final Invoice:
                        <v-chip color="green" dark v-if="editedItem.final_invoice == 1" class="ml-2">Yes</v-chip>
                        <v-chip color="red" dark v-else class="ml-2">No</v-chip>
                      </template>
                    </v-switch>
                  </v-col>
                  <v-col cols="12" sm="5" md="5">
                    <v-switch v-model="editedItem.paid" color="info" hide-details>
                      <template v-slot:label>
                        Paid:
                        <v-chip color="green" dark v-if="editedItem.paid == 1" class="ml-2">Yes</v-chip>
                        <v-chip color="red" dark v-else class="ml-2">No</v-chip>
                      </template>
                    </v-switch>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="editedItem.amount" label="Amount"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea v-model="editedItem.note" autocomplete="Note" label="Note"></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save(items)">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.paid="{ item }">
      <v-chip color="green" dark v-if="item.paid ==  1">Yes</v-chip>
      <v-chip color="red" dark v-else>No</v-chip>
    </template>
    <template v-slot:item.final_invoice="{ item }">
      <v-chip color="green" dark v-if="item.final_invoice == 1">Yes</v-chip>
      <v-chip color="red" dark v-else>No</v-chip>
    </template>
    <template v-slot:item.actions="{ item }">
      <!--<v-icon
        small
        class="mr-2"
        @click="seeItem(item)"
        v-b-tooltip.hover
        title="Print"
      >mdi-printer</v-icon>-->
      <v-icon
        small
        class="mr-2"
        @click="statusItem(item)"
        v-b-tooltip.hover
        title="Change Status"
      >mdi-cash-multiple</v-icon>
      <v-icon
        small
        class="mr-2"
        @click="editItem(item, 'dialog')"
        v-b-tooltip.hover
        title="Edit"
      >mdi-pencil</v-icon>
      <v-icon small @click="deleteItem(item, items)" v-b-tooltip.hover title="Delete">mdi-delete</v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="reset">Reset</v-btn>
    </template>
    <template v-slot:item.amount="{ item }">{{ formatAmount(item.amount) }}</template>
    <template slot="footer">
      <table>
        <tr class="border border-right-0 border-left-0 border-bottom-0 text-center">
          <td colspan>
            <v-chip color="lighten-1 mr-4">
              Items: &nbsp;
              <strong class="ml-2">{{ itemsFiltered }}</strong>
            </v-chip>
            <v-chip color="lighten-1">
              Amount: &nbsp;
              <strong class="ml-2">{{ totalAmount }}</strong>
            </v-chip>
          </td>
        </tr>
      </table>
    </template>
  </v-data-table>
</template>

<script>
import genericService from "./../../../services/generic.service";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  data: () => ({
    uri:"invoices",
    workOrdersList: [],
    today: new Date().toISOString().substr(0, 10),
    formNewtItem: true,
    dialog: false,
    dialog2: false,
    dialog3: false,
    search: "",
    popDatepick1: false,
    totalAmount: 0,
    radios: "",
    editedIndex: -1,
    editedItem: {
      work_order: null,
      paid: 0,
      id: "",
      to: "",
      amount: null,
      date: new Date().toISOString().substr(0, 10),
      final_invoice: 0,
      number: "",
    },
    defaultItem: {
      work_order: null,
      paid: 0,
      id: "",
      to: "",
      amount: null,
      date: new Date().toISOString().substr(0, 10),
      final_invoice: 0,
      number: "",
    },
    switch1: true,

    /* Filtros & Loading */
    loading: true,
    filters: [
      { text: "All", value: 99 },
      { text: "Nr", value: 0 },
      { text: "Work Order", value: 1 },
      { text: "To", value: 2 },
      { text: "Amount", value: 5 }
    ],
    date1: "",
    date2: "",
    formatedDates: "",
    rangeDates: [],
    rangeCalendar: false,
    dialogFilters: false,
    itemsFiltered: 0,
    companyList: [],
    filterPaid:"",
    filterWorkOrder:"",
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Invoice" : "Edit Invoices";
    },
    formTitle2() {
      return this.editedIndex === -1
        ? "Change Invoices Status"
        : "Change Invoices Status";
    },
    /* Filters */
    dateRangeText() {
      return this.formatDate(this.rangeDates);
    },
    ...mapGetters([ 'currentUser' ])
  },

  props: ["headers", "items", "search_tool", "current_wo_id"],

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialog2(val) {
      val || this.close();
    },
    dialog3(val) {
      val || this.close();
    }
  },

  methods: {
    editItem(item, dialog) {
      /* Listado de Dialogs */
      if (dialog == "dialog") {
        this.formNewtItem = false;
        this.dialog = true;
      }
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },

    deleteItem(item, list) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        const index = list.indexOf(item);
        if (result.value) {
          genericService.deleteItem(this.uri, item.id);
          list.splice(index, 1);
          Swal.fire({
            title: 'Deleted!',
            text: "Your record has been deleted.",
            icon: 'success'
          })
        }
      })
    },

    seeItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      
      //window.open('http://localhost/simonsen_api/reports/pdf/invoices/'+item.id+'/', '_blank');
      //window.open('http://66.97.33.159/api/reports/pdf/invoices/'+item.id+'/', '_blank');
      window.open('http://crm.simonsen.com.ar/api/reports/pdf/invoices/'+item.id+'/', '_blank');
    },

    statusItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog2 = true;
    },

    close() {
      /* Listado de Dialogs */
      this.dialog = false;
      this.dialog2 = false;
      this.dialog3 = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save(items) {
      var _self = this;
      
      if (this.editedIndex > -1) {
        Object.assign(items[this.editedIndex], this.editedItem);
        genericService.updateRecord("/"+this.uri+"/" + this.editedItem.id, this.editedItem, function(){
          Swal.fire({
            title: "",
            text: "Record has been successfully saved!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary"
          });
        });
      } else {
        this.editedItem.work_order = this.current_wo_id;
        items.push(this.editedItem);
        genericService.createRecord("/"+this.uri, this.editedItem, function(rta){
          if(rta != undefined) {
            Swal.fire({
              title: "",
              text: "Record has been successfully saved!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary"
            });
            _self.editedItem.id = rta.id;
          }
        });
      }
      this.currentItems(items);
      this.close();
    },

    reset() {
      this.$emit("initialize");
    },

    toNumber(nr) {
      nr = parseFloat(nr);
      return nr;
    },

    formatCurrencyAmount(amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2
      });

      return formatter.format(amount);
    },

    formatAmount(nr) {
      if((nr == undefined) || (nr == null) || (isNaN(nr))) {
        return 0;
      }
      return nr;
    },

    /* Filtros & Loading */
    currentItems(items) {
      this.loading = true;
      setTimeout(() => {
        this.itemsFiltered = items.length;

        // Juntar numeros y formatearlos
        var sum = items.reduce((a, item) => a + this.formatAmount(parseFloat(item.amount)), 0);
        this.totalAmount = this.formatCurrencyAmount(sum);

        this.loading = false;
      }, 300);
    },
    
    /* BOM - Filtros */
    clearFilterItems() {
      var _self = this;
      _self.rangeDates = [];
      _self.dateRangeText = "";
      _self.filterWorkOrder = "";
      _self.filterPaid = "";

      genericService.getRecordListWithLimit(
        '/'+this.uri, 
        10, 
        "date", 
        _self.currentUser.role,
        _self.currentUser.id,
        function(records){
         _self.items = records;
         _self.dialogFilters = false;
      });
    },

    filterItems() {
      var _self = this;
      var params = {
        "module": _self.uri,
        "range": _self.rangeDates,
        "wo": _self.filterWorkOrder,
        "paid": _self.filterPaid,
      }

      genericService.getRecordListWithParams(params, _self.currentUser.role, _self.currentUser.id, function(records){
         _self.items = records;
         _self.dialogFilters = false;
      });
    },

    formatDate(dates) {
      if (dates[1]) {
        const [year1, month1, day1] = dates[0].split("-");
        const [year2, month2, day2] = dates[1].split("-");
        this.date1 = `${day1}/${month1}/${year1}`;
        this.date2 = `${day2}/${month2}/${year2}`;

        this.formatedDates = this.date1 + " ~ " + this.date2;
      } else {
        this.formatedDates = "";
      }
      return this.formatedDates;
    },

    focusField(target) {
      setTimeout(() => {
        target.focus();
      }, 500);
    },
    /* EOM - Filtros */
    
    formNewItem(){
      this.formNewtItem = true;
      setTimeout(() => {
        this.focusField(this.$refs.formFirstField);
      }, 200)
    }
  },
  mounted() {
    var _self = this;
    
    if(this.search_tool == true){
      this.focusField(this.$refs.search);
    }

    genericService.getRecordList('/list/contacts/Company', function(records){
        _self.companyList = records
    });

    if(this.currentUser.role == "Admin") {
      genericService.getRecordList('/workorders/', function(records){
          _self.workOrdersList = records.reverse();
      });
    }

  }
};
</script>
