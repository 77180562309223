<template>
  <v-app>
    <transition name="fade-in-up">
      <div class="row">
        <div class="col-md-12">
          <InvoicesList
            :headers="headersInvoices"
            :items="itemsInvoices"
            @initialize="initialize"
            :search_tool="true"
          ></InvoicesList>
        </div>
      </div>
    </transition>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import InvoicesList from "@/views/pages/invoices/InvoicesList.vue";
import genericService from "./../../../services/generic.service";
import { mapGetters } from "vuex";

export default {
  components: {
    InvoicesList,
  },

  data: () => ({
    uri: "invoices",
    headersInvoices: [
      { text: "ID", value: "id", sortable: false, filterable: true },
      { text: "Number", value: "number", sortable: false, filterable: true },
      {
        text: "Work Order",
        value: "work_order",
        sortable: false,
        filterable: true,
      },
      { text: "To", sortable: false, value: "company_label", filterable: true },
      { text: "Date", value: "date", sortable: false },
      { text: "Amount", value: "amount", sortable: false, filterable: true },
      { text: "Final Invice", value: "final_invoice", sortable: false },
      { text: "Paid", value: "paid", sortable: true },
      { text: "Actions", value: "actions", sortable: false },
    ],
    itemsInvoices: [],
  }),

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      var _self = this;
      if (this.currentUser.role == "Admin") {
        genericService.getRecordListWithLimit(
          "/" + this.uri,
          100,
          "date",
          _self.currentUser.role,
          _self.currentUser.id,
          function (records) {
            _self.itemsInvoices = records;
          }
        );
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Invoices" }]);
  },

  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>
