import ApiService from "@/common/api.service";

export default {
    getRecordList(module, callback){
        ApiService.get(module, '')
        .then(({ data }) => {
            callback(data);
            return;
        })
        .catch(({ error }) => {
            callback(error);
            return;
        });
    },

    getRecordListWithLimit(module, limit, orderByDesc, currentUserRole, currentUserId, callback){
        //('/(\w+)/limit/(\d+)/orderdesc/(\w+)/
        ApiService.get(module+'/limit/'+limit+'/orderdesc/'+orderByDesc+'/userrole/'+currentUserRole+'/userid/'+currentUserId, '')
        .then(({ data }) => {
            callback(data);
            return;
        })
        .catch(({ error }) => {
            callback(error);
            return;
        });
    },

    getRecordListWithParams(params, currentUserRole, currentUserId, callback){
        ApiService.post("/list/params/filter/userrole/"+currentUserRole+"/userid/"+currentUserId, params)
        .then(({ data }) => {
            callback(data);
            return;
        })
        .catch(({ error }) => {
            callback(error);
            return;
        });
    },

    getRelatedWORecordList(uri, WOId, relatedModule, userrole, userid, callback){
        ApiService.get(uri+'/'+WOId+'/related/'+relatedModule+'/'+userrole+'/'+userid, '')
        .then(({ data }) => {
            callback(data);
            return;
        })
        .catch(({ error }) => {
            callback(error);
            return;
        });
    
    },

    getRelatedList(module, relatedType, relatedId, callback){
        ApiService.get(module+'/related/'+relatedType+'/'+relatedId+'/', '')
        .then(({ data }) => {
            callback(data);
            return;
        })
        .catch(({ error }) => {
            callback(error);
            return;
        });
    },

    getDashboardDetailDormantWO(userId, userRole, callback){
        ApiService.get('/dashboard/detail/dormantwo/'+userId+'/'+userRole, '')
        .then(({ data }) => {
            callback(data);
            return;
        })
        .catch(({ error }) => {
            callback(error);
            return;
        });
    },

    getRecord(module, recordId, callback){
        ApiService.get(module+'/'+recordId, '')
        .then(({ data }) => {
            callback(data);
            return;
        })
        .catch(({ error }) => {
            callback(error);
            return;
        });
    },

    getFile(recordId, callback){
        ApiService.get('files/'+recordId+'/download/', '', {responseType: 'blob'})
        .then(({ data }) => {
            callback(data);
            return;
        })
        .catch(({ error }) => {
            callback(error);
            return;
        });
    },

    getRelatedFiles(module, recordId, callback){
        ApiService.get(module+'/'+recordId+'/files/', '', {responseType: 'blob'})
        .then(({ data }) => {
            callback(data);
            return;
        })
        .catch(({ error }) => {
            callback(error);
            return;
        });
    },

    createRecord(module, item, callback){
        ApiService.post(module, item)
        .then(({ data }) => {
            callback(data);
            return;
        })
        .catch(({ error }) => {
            callback(error);
            return;
        });
    },

    updateRecord(module, item, callback){
        ApiService.put(module, item)
        .then(({ data }) => {
            callback(data);
            return;
        })
        .catch(({ error }) => {
            callback(error);
            return;
        });
    },
    
    deleteItem(module, recordId){
        ApiService.delete("/" + module + "/" + recordId, "")
        .then(() => {
            return;
        })
    },

    getReport(reportName, callback){
        ApiService.get('/dashboard/reports/'+reportName, '')
        .then(({ data }) => {
            callback(data);
            return;
        })
        .catch(({ error }) => {
            callback(error);
            return;
        });
    },
};
